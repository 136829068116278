export default function FarmlandLoader(props) {

  return <>
    <div className="farmland-loader">
      <ul className="farmland-loader-inner">
        <li className="farmland-loader-line-wrap">
          <div className="farmland-loader-line"></div>
        </li>
        <li className="farmland-loader-line-wrap">
          <div className="farmland-loader-line"></div>
        </li>
        <li className="farmland-loader-line-wrap">
          <div className="farmland-loader-line"></div>
        </li>
        <li className="farmland-loader-line-wrap">
          <div className="farmland-loader-line"></div>
        </li>
        <li className="farmland-loader-line-wrap">
          <div className="farmland-loader-line"></div>
        </li>
      </ul>
      <p className="farmland-loader-text">Loading...</p>
    </div>
  </>
}
