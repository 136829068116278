import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Switch, Route } from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import FarmlandLoader from 'src/components/FarmlandLoader';
import Introduce from 'src/routes/Introduce/Introduce';
import 'src/assets/styles/index.css';

const App = lazy(() => import('./App'));

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path={['/authorize/*', '/management/*']}>
        <Suspense fallback={<FarmlandLoader />}>
          <App />
        </Suspense>
      </Route>
      <Route path="**">
        <Introduce />
      </Route>
    </Switch>
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
