import { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import logo1 from 'src/assets/images/onesixtech.png';
import logo2 from 'src/assets/images/onesixtech.png';
import menu from 'src/assets/images/menu.png';
import flowChart from 'src/assets/images/flow-chart.jpg';
import 'src/routes/Introduce/style.css';

// changes

export default function Introduce() {
  const history = useHistory();

  const homeRef = useRef();
  const workRef = useRef();
  const aboutRef = useRef();
  const contactRef = useRef();
  const scrollTo = (refElement) => {
    setMenuHide(true);
    refElement.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest'
    });
  };

  const [menuHide, setMenuHide] = useState(true);
  const toggleMenuHide = () => setMenuHide(!menuHide);

  return (
    <div className="farmland-introduce">
      <div
        style={{
          position: 'fixed',
          top: 0,
          width: '100%',
          backgroundColor: '#ffffff',
          borderBottom: '1px solid #dbdbdb',
          zIndex: 9999
        }}
      >
        <div className="farmland-introduce-container farmland-introduce-header flex">
          <a href="/">
            <img src={logo1} alt="header" style={{ height: '100%' }} />
          </a>
          <div className="flex-1"></div>
          <div
            className="farmland-introduce-header-login"
            onClick={() => history.push({ pathname: '/authorize/login' })}
          >
            LOGIN
          </div>
          <div className="farmland-introduce-header-menu-mobile-container">
            <img src={menu} alt="menu" onClick={() => toggleMenuHide()} />
          </div>
          <ul className="farmland-introduce-header-menu flex">
            <li onClick={() => scrollTo(homeRef.current)}>HOME</li>
            <li onClick={() => scrollTo(workRef.current)}>HOW IT WORKS</li>
            <li onClick={() => scrollTo(aboutRef.current)}>ABOUT US</li>
            <li onClick={() => scrollTo(contactRef.current)}>CONTACT US</li>
          </ul>
          <ul
            className="farmland-introduce-header-menu-mobile"
            style={{ display: menuHide ? 'none' : 'flex' }}
          >
            <li onClick={() => scrollTo(homeRef.current)}>HOME</li>
            <li onClick={() => scrollTo(workRef.current)}>HOW IT WORKS</li>
            <li onClick={() => scrollTo(aboutRef.current)}>ABOUT US</li>
            <li onClick={() => scrollTo(contactRef.current)}>CONTACT US</li>
          </ul>
        </div>
      </div>
      <div className="text-center farmland-introduce-welcome">
        <div
          style={{ position: 'absolute', top: '-100px' }}
          ref={homeRef}
        ></div>
        <h2 className="farmland-introduce-welcome-title">
          At OneSix Technology, we believe the best solutions tend to be a combination
          of technology and services.
        </h2>
        <p className="farmland-introduce-welcome-sub-title">
          While we tend to think of innovation in terms of new technology,
          solutions can also come in the form of new types of services that
          offer improvements to old ways of doing things, meet previously
          unidentified needs or fill gaps in the market. More often, innovation
          solutions use technology to support and dispatch new services so that
          the best solutions tend to be a combination of technology and
          services.
        </p>
        <button
          className="farmland-introduce-welcome-button farmland-introduce-welcome-button-login"
          onClick={() => history.push({ pathname: '/authorize/login' })}
        >
          LOGIN
        </button>
        {/* <button
          className="farmland-introduce-welcome-button farmland-introduce-welcome-button-register"
          onClick={() => history.push({ pathname: '/authorize/register' })}
        >
          REGISTER WITH US
        </button> */}
      </div>
      <div className="text-center farmland-introduce-work">
        <div
          style={{ position: 'absolute', top: '-100px' }}
          ref={workRef}
        ></div>
        <div className="farmland-introduce-container">
          <h3 className="farmland-introduce-work-title">How It Works</h3>
          <h4 className="farmland-introduce-work-sub-title">
            Providing expert consulting services to determine what type of
            innovation structure would work best within each business entity.
            Consulting on setting up a new structure that fully integrates
            innovation and makes it a priority.
          </h4>
          <ul className="farmland-introduce-work-list">
            <li className="farmland-introduce-work-item">
              <div className="farmland-introduce-work-icon-container">
                <div className="farmland-introduce-work-icon1"></div>
              </div>
              <div className="farmland-introduce-work-content">
                <h4>Platforms</h4>
                <p className="farmland-introduce-work-item-description">
                  Innovation challenge and idea management platforms
                </p>
              </div>
            </li>
            <li className="farmland-introduce-work-item-divider">
              .............
            </li>
            <li className="farmland-introduce-work-item">
              <div className="farmland-introduce-work-icon-container">
                <div className="farmland-introduce-work-icon2"></div>
              </div>
              <div className="farmland-introduce-work-content">
                <h4>Tools</h4>
                <p className="farmland-introduce-work-item-description">
                  Idea review and advancement tools
                </p>
              </div>
            </li>
            <li className="farmland-introduce-work-item-divider">
              .............
            </li>
            <li className="farmland-introduce-work-item">
              <div className="farmland-introduce-work-icon-container">
                <div className="farmland-introduce-work-icon3"></div>
              </div>
              <div className="farmland-introduce-work-content">
                <h4>Software</h4>
                <p className="farmland-introduce-work-item-description">
                  Collaboration software, Technology landscaping and scouting
                  software, Project planning software
                </p>
              </div>
            </li>
            <li className="farmland-introduce-work-item-divider">
              .............
            </li>
            <li className="farmland-introduce-work-item">
              <div className="farmland-introduce-work-icon-container">
                <div className="farmland-introduce-work-icon4"></div>
              </div>
              <div className="farmland-introduce-work-content">
                <h4>Big data technology</h4>
                <p className="farmland-introduce-work-item-description">
                  Mining big data technology to report on a specific technology
                  or product space
                </p>
              </div>
            </li>
            <li className="farmland-introduce-work-item-divider">
              .............
            </li>
            <li className="farmland-introduce-work-item">
              <div className="farmland-introduce-work-icon-container">
                <div className="farmland-introduce-work-icon5"></div>
              </div>
              <div className="farmland-introduce-work-content">
                <h4>Technology solutions</h4>
                <p className="farmland-introduce-work-item-description">
                  Users can connect with a solver, expert, start up, discover
                  new patents
                </p>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div className="farmland-introduce-container text-center farmland-introduce-about">
        <div
          style={{ marginTop: '-150px', position: 'absolute' }}
          ref={aboutRef}
        ></div>
        <h3
          className="farmland-introduce-about-title"
          style={{ paddingTop: '1px' }}
        >
          About Us
        </h3>
        <h4 className="farmland-introduce-about-sub-title">
          At OneSix Technology, we believe the best solutions tend to be a combination
          of technology and services.
        </h4>
        <ul className="farmland-introduce-about-list">
          <li className="farmland-introduce-about-item">
            <div className="farmland-introduce-about-item-inner1"></div>
          </li>
          <li className="farmland-introduce-about-item">
            <div className="farmland-introduce-about-item-inner2"></div>
          </li>
          <li className="farmland-introduce-about-item">
            <div className="farmland-introduce-about-item-inner3"></div>
          </li>
        </ul>
        <p className="farmland-introduce-about-description">
          While we tend to think of innovation in terms of new technology,
          solutions can also come in the form of new types of services that
          offer improvements to old ways of doing things, meet previously
          unidentified needs or fill gaps in the market. More often, innovation
          solutions use technology to support and dispatch new services so that
          the best solutions tend to be a combination of technology and
          services.
        </p>
        <p className="farmland-introduce-about-description">
          Even the biggest companies will not have the manpower necessary to
          achieve ongoing innovation within their own walls. They will have to
          use innovation solutions to find the right startup partners, locate
          emerging technology, recognize trends and much more. Fortunately,
          innovation solutions are scalable and flexible enough that even small
          businesses can afford to use these tools and reap the rewards. This is
          where OneSix Technology chases the opportunity to look at providing
          industry solutions that add value through a network of specialised
          professionals.
        </p>
      </div>
      <div className="text-center text-center farmland-introduce-partner">
        <div className="farmland-introduce-container">
          <h3 className="farmland-introduce-partner-title">Partner With Us</h3>
          <h4 className="farmland-introduce-partner-sub-title">
            Innovation solutions can also be particular to the innovation
            industry, which is tasked with partnering with companies to create a
            culture of innovation and help develop meaningful innovations. In
            this case, partnerships can achieve a whole suite of tools that are
            used to drive innovation within companies. Innovation solutions used
            to drive internal innovation can range from consulting services to
            software automation that allows teams to advance, scout, discover
            and accelerate the release of ideas within different industries. As
            advances are made, competition increases and consumer expectations
            change; They feel more empowered than ever to demand better products
            and services from companies. This has created a climate where
            innovation isn’t an option. It must be prioritized and
            systematically pursued in order to ensure the that companies can
            keep their doors open.
          </h4>
          <h4 className="farmland-introduce-partner-sub-title">
            Innovative solutions support companies in their pursuit of
            innovation by allowing them to reorganize their innovation
            structure, create a more cutting-edge approach and build a culture
            of innovation. In the past, innovation has been contained within the
            research and development departments, but it now goes beyond these
            limitations and seeks to take advantage of both internal and
            external resources. It isn’t enough to simply stay relevant while
            the competition continues to advance by leaps and bounds.
          </h4>
          <ul className="farmland-introduce-partner-list">
            <li className="farmland-introduce-partner-item">
              <div className="farmland-introduce-partner-item-inner1"></div>
            </li>
            <li className="farmland-introduce-partner-item">
              <div className="farmland-introduce-partner-item-inner2"></div>
            </li>
            <li className="farmland-introduce-partner-item">
              <div className="farmland-introduce-partner-item-inner3"></div>
            </li>
            <li className="farmland-introduce-partner-item">
              <div className="farmland-introduce-partner-item-inner4"></div>
            </li>
          </ul>
        </div>
      </div>
      <div className="farmland-introduce-container text-center farmland-introduce-contact">
        <div
          style={{ marginTop: '-150px', position: 'absolute' }}
          ref={contactRef}
        ></div>
        <h3 className="farmland-introduce-contact-title">Contact Us</h3>
        <h4 className="farmland-introduce-contact-sub-title">
          OneSix Technology will be a driving force behind digitalization. Both
          future trends and potential risks for your company can be identified
          here, as well as contact with important business partners and
          technology suppliers.
        </h4>
        <h4 className="farmland-introduce-contact-sub-title">
          With the help of our established network of industry, technology, and
          innovation experts, OneSix Technology strives to master the challenges of
          digitalization, resulting in a decisive competitive advantage Our
          Innovation Solutions Team have expertise in a number of areas and can
          help tackle your individual company challenges. Share with us your
          company’s objectives and we will develop an individually targeted
          solution that addresses your challenges.
        </h4>
        <ul className="farmland-introduce-contact-list">
          <li className="farmland-introduce-contact-item">
            <div className="farmland-introduce-contact-icon1"></div>
            <div className="farmland-introduce-contact-content">
              <h4>Address</h4>
              <p>OneSix Technology Pty Ltd</p>
              <p>160 Second Avenue, West Hoxton NSW 2171</p>
            </div>
          </li>
          <li className="farmland-introduce-contact-item">
            <div className="farmland-introduce-contact-icon2"></div>
            <div className="farmland-introduce-contact-content">
              <h4>Contact</h4>
              <p>P: 0475 552 555 E: info@afssform.com.au</p>
            </div>
          </li>
        </ul>
      </div>
      <div className="text-center farmland-introduce-footer">
        <div className="farmland-introduce-footer-logo"></div>
        <div style={{ backgroundColor: '#1f110f', marginTop: '-1px' }}>
          <img
            src={logo2}
            alt="footer"
            style={{ width: '144px', margin: '50px 0px' }}
          />
          <div className="farmland-introduce-container">
            <hr style={{ borderTop: '0px', borderColor: '#352b29' }} />
            <div
              className="flex"
              style={{
                padding: '15px 15px 20px',
                color: '#e0e0e0',
                fontWeight: 600
              }}
            >
              <div>&copy; 2023 All Right Reserved.</div>
              <div className="flex-1"></div>
              <div>OneSix Technology Pty Ltd</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
